.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #e4dbd2; 
    color: black;
    position: sticky;
    top: 0; 
    z-index: 1000;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1); 
  }
  
  .logo {
    width: 17%;
    height: 70px; 
  }
  
  .menu {
    justify-content: flex-end;
  }

  .nav-menu ul {
    list-style: none;
    display: flex;
    margin: 25px;
    padding: 0;
  }
  
  .nav-menu li {
    margin-left: 30px; 
  }
  
  .nav-menu a {
    color: rgba(0, 0, 0, 0.459);
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-menu a:hover {
    text-decoration: underline; 
    color: black;
  }

  .nav-menu a.active {
    font-weight: bold;
    text-decoration: underline;
    color: black;
  }

  /* Hamburger button for mobile */
.hamburger {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .logo {
      width: 25%;
      height: 60px;
    }
  
    /* Hide regular menu on mobile */
    .nav-menu ul {
      flex-direction: column;
      display: none; /* Hidden by default on mobile */
      position: absolute;
      top: 70px;
      right: 0;
      background-color: #e4dbd2;
      width: 100%;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }
  
    /* Show the menu when it's open */
    .nav-menu.open ul {
      display: flex;
    }
  
    .nav-menu li {
      margin: 10px 0;
      text-align: center;
    }
  
    /* Show the hamburger button on mobile */
    .hamburger {
      display: block;
    }
  }
  