html {
  scroll-behavior: smooth;
}


.section {
  height: auto; 
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.section-lines-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  z-index: 2;
}

.section-lines-container .line {
  width: 100%;
  height: 1px;
  background-color: #00000069;
  margin: 0px 46px;
  border: none;
}

.section-icon {
  width: 70px;
  height: auto;
}

/* HOME  */
#home {
  background-color: #e4dbd2; 
}

.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  position: relative;
  overflow: hidden;
}

.home-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/homebg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.15;
}

.home-cards-container {
  display: flex;
  width: 100%;
  z-index: 2;
  padding-bottom: 120px;
  padding-top: 30px;
}

.home-card.left-card {
  flex: 4;
  padding: 20px;
}

.home-card.right-card {
  flex: 6;
  padding: 35px;
  text-align: right;
  padding-left: 20px;
  padding-top: 150px;
}

.home-card.left-card img {
  width: 100%;
  height: auto;
  max-width: 700px;
}

.home-card.right-card h1 {
  margin: 10px 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  color: #240F3A;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-card.right-card p {
  font-size: 30px;
  line-height: 1;
  font-family: 'Times New Roman', Times, serif;
  color: #240F3A;
  font-weight: bolder;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px 0px 30px 0px;
}

.home-button {
  font-size: 1.5rem;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border-color: #AF9792;
  background: #AF9792;
  box-shadow: 5px 5px 5.3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 50%;
  font-size: 27px;
  border-width: inherit;
}

.home-button:hover {
  background-color: #95817d;
}

.home-button:active {
  box-shadow: none;
  transform: translate(3px, 3px);
}

.home-button img {
  width: 27px;
  height: auto;
  margin-left: 15px;
  justify-content: center;
}

.bottom-card-container {
  width: 100%;
  background-color: #AF9792;
  position: absolute;
  bottom: 0; 
  left: 0;
  z-index: 2;
  height: 17%;
  display: flex;
}

.bottom-card.left-card {
  flex: 4;
  padding: 15px;
  padding-right: 0px;
  margin-left: 5%;
  z-index: 3;
  height: auto;
}

.bottom-card.left-card img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.bottom-card.right-card-container {
  flex: 6;
  z-index: 3;
  display: flex;
}

.bottom-left-img{
  flex: 2;
}

.bottom-left-img img {
  height: auto;
  width: 30%;
  margin-top: 35px;
  padding-left: 50px;
}

.bottom-right-text {
  flex: 8;
  justify-content: left;
}

.bottom-right-text h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  font-size: 22px;
}

/* About Section */
.about-section {
  padding: 0px 20px;
  padding-top: 95px;
}

.cards-container {
  display: flex;
  justify-content: space-around; 
  align-items: stretch; 
  margin: 20px 50px;
  max-width: 1500px;
  gap: 25px;  
}

.card {
  background-color: #F5F1E8;
  border: 1px solid #F5F1E8;
  border-radius: 5px;
  padding: 30px;
  width: 30%; 
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.card h4 {
  color: #443518;
  margin-bottom: 30px;
  margin-top: 20px;
  font-weight: bold;
}

.card p {
  color: #3A1515;
  line-height: 1;
  font-size: 20px;
}

.about-sub-icon {
  width: 60px;
  max-width: 100%;
  height: auto;
}

.starter-card-container {
  display: flex;
  background-color: #4a391e;
  padding: 35px;
  justify-content: space-between;
  min-height: auto;
  height: auto;
  margin: 0px 50px;
  margin-top: 60px;
}

.starter-left-card {
  flex: 4;
  display: flex;
}

.starter-left-text {
  flex: 9;
}

.starter-left-card .starter-left-text h2 {
  color: #FEFEFF;
}

.starter-left-card .starter-left-text h1 {
  color: #FEFEFF;
  font-weight: normal;
  text-align: right;
}

.starter-right-line {
  flex: 1;
}

.vertical-line {
  border-left: 3px solid #ffffff;
  height: 500px;
  margin-left: 30px;
}

.starter-right-card {
  flex: 6;
}

.starter-right-cards {
  background-color: #ffffff;
  border: 1px solid #F5F1E8;
  border-radius: 5px;
  width: 90%;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 5%;
  height: auto;
  margin-bottom: 20px;
  padding: 20px 10px;
}

.starter-right-cards .subtitle {
  display: flex;
  height: 50px;
}

.about-subtitle-icon {
  width: 80px;
  max-width: 100%;
  height: 80px;
  padding-left: 10px;
}

.about-subtitle-icon-hui {
  width: 80px;
  max-width: 100%;
  height: 80px;
  padding-left: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.starter-right-cards .subtitle p {
  color: #443518;
  font-size: 26px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 20px;
}

.starter-right-cards .subtitle1 {
  padding-top: 30px;
  padding-left: 10px;
}

.starter-right-cards .subtitle1 p {
  color: #443518;
  font-size: 20px;
  font-weight: normal;
}

/* Modern Section */
.modern-section {
  padding-top: 95px;
}

.modern-card-container {
  background-color: #F5F1E8;
  justify-content: space-between;
  min-height: auto;
  margin-top: 50px;
  padding-bottom: 30px;
}

.modern-card {
  display: flex;
}

.modern-card-left {
  flex: 5;
  height: 85vh;
  position: relative;
}

.modern-card-left .first-card {
  position: absolute;
  top: 10%;
  left: 25%;
  width: 40%;
  height: 50%;
  z-index: 1;
  border-radius: 10px;
  box-shadow: -11px 9px 4px rgba(0, 0, 0, 0.25);
}

.modern-card-left .second-card {
  position: absolute;
  top: 44%;
  left: 45%;
  width: 40%;
  height: 58%;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 8px 9px 4px rgba(0, 0, 0, 0.25);
}

.modern-card-right {
  flex: 5;
  justify-content: flex-start;
  padding: 30px 80px;
}

.modern-card-right h3 {
  color: #131D49;
  margin-top: 50px;
}

.modern-card-right h2 {
  color: #000000;
}

.modern-card-right p {
  color: #4C4B4B;
  font-size: 22px;
  line-height: 2;
  font-weight: normal;
}

.modern-right-container {
  height: auto;
  width: 100%;
  margin-top: 40px;
}

.modern-right-container h3 {
  text-align: center;
  color: #000000;
}

.modern-cards-container {
  width: 100%;
  height: auto;
  background-color: #443518;
  border-radius: 20px;
  text-align: center;
  box-shadow: 9px 9px 4px rgba(0, 0, 0, 0.25);
}

.modern-cards-container h2 {
  color: #ffffff;
  padding: 20px 0px;
}

.modern-bottom-card {
  width: 100%;
  height: auto;
  display: flex;
}

.modern-icon {
  width: 60px;
  height: 60px;
}

.modern-bottom-icon{
  width: 40px;
  height: 40px;
  flex: 3;
  text-align: end;
}

.modern-bottom-text{
  flex: 7;
  color: #000000;
  font-size: 21px;
  padding: 0px 0px 0px 15px;
}

/*Career Section*/
.career-section {
  padding: 95px 20px 40px 20px;
}

.career-card-container {
  padding: 10px 0px 0px 65px;
}

.career-card-container h3 {
  color: #131D49;
  margin-top: 50px;
}

.career-card-container h2 {
  color: #000000;
  text-align: center;
}

.career-card-container p {
  color: #4C4B4B;
  font-size: 26px;
  font-weight: normal;
  padding-bottom: 35px;
  padding-left: 70%;
}

.career-card-left h4 {
  color: #000000;
  font-size: 30px;
}

.career-cards-container {
  min-height: auto;
  width: 100%;
  display: flex;
  text-align: center;
  margin-top: 50px;
}

.career-cards {
  background-color: #F5F1E8;
  padding: 30px 10px;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0px 50px;
  border-radius: 20px;
  flex: 5;
}

.career-cards-logo-mcma {
  width: 380px;
  height: 100px;
}

.career-cards-logo-mlvk{
  width: 100px;
  height: 100px;
}

.career-cards-logo-mly{
  width: 140px;
  height: 100px;
}

.career-cards-logo-mqa{
  width: 230px;
  height: 100px;
}

.career-cards-text {
  margin-top: 30px;
}

.career-cards p{
  color: #000000;
  font-weight: bold;
  line-height: 1;
}

/*Contact Section*/
.contact-card-container {
  background-color: #F5F1E8;
  min-height: auto;
  width: 100%;
  margin-top: 50px;
}

.contact-logo {
  width: 300px;
  height: auto;
  padding: 0px 20px;
}

.contact-cards-container {
  display: flex;
}

.contact-cards-1 {
  flex: 2;
  color: #000000;
  padding: 0px 50px;
  text-align: left;
}

.contact-cards-2 {
  flex: 5;
  color: #000000;
  padding: 0px 50px;
  text-align: left;
}

.contact-cards-3 {
  flex: 5;
  color: #000000;
  padding: 0px 50px;
  text-align: left;
}

.contact-cards h5 {
  color: #443518;
}

.contact-media-logo {
  display: flex;
  padding-bottom: 20px;
}

.instagram {
  flex: 7;
  width: 30px;
  height: auto;
  max-width: 30px;
}

.facebook {
  flex: 3;
  width: 30px;
  height: auto;
  max-width: 30px;
  padding-left: 20px;
}

.contact-cards-content {
  display: flex;
  margin-bottom: 20px;
}

.contact-icon {
  width: 30px;
  height: 30px;
  max-width: 30px;
  flex: 2;
}

.contact-text {
  flex: 8;
  margin: 0px 10px;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  .home-cards-container {
    display: flex;
    width: 100%;
    z-index: 2;
    height: auto;
  }

  .home-card.left-card {
    flex: 1; 
  }


.home-card.right-card {
    flex: 1; 
    text-align: right   ;
  }


  .home-card.right-card h1 {
    font-size: 35px;
}

  .home-card.right-card h2 {
    font-size: 30px; 
  }

  .home-card.right-card p {
    font-size: 20px; 
    line-height: normal;
  }

  .home-button {
    width: 100%;
    font-size: 20px; 
    margin-top: -10px;
    margin-bottom: 50px;
  }

  .bottom-card-container {
    height: auto; 
    padding-left: 0px;
  }

  .bottom-card.left-card,
  .bottom-card.right-card-container {
    flex: 1; 
    padding-right: 40px;
  }

  .bottom-left-img img {
    width: 50%; 
    margin-top: 20px; 
    padding-left: 0; 
  }

  .bottom-right-text h3 {
    font-size: 18px; 
    margin-top: 20px;
  }

  .bottom-right-text {
    flex: 6 1;
    justify-content: left;
  }

  .starter-left-card .starter-left-text h2 {
    font-size: 30px;
  } 

  .starter-left-card .starter-left-text h1 {
    font-size: 35px;
  }

  .starter-right-cards .subtitle p {
    font-size: 22px;
  } 

  .starter-right-cards .subtitle1 p {
    font-size: 18px;
  }

  .modern-section {
    padding-top: 90px;
}

  .modern-card-right p {
    font-size: 20px;
  }

  .modern-card-left .first-card {
    position: absolute;
    top: 10%;
    left: 25%;
    width: 53%;
    height: 50%;
    z-index: 1;
    border-radius: 10px;
    box-shadow: -11px 9px 4px rgba(0, 0, 0, 0.25);
  }

  .modern-card-left .second-card {
    position: absolute;
    top: 44%;
    left: 45%;
    width: 55%;
    height: 58%;
    z-index: 2;
    border-radius: 10px;
    box-shadow: 8px 9px 4px rgba(0, 0, 0, 0.25);
  }
  
  .modern-bottom-icon{
    flex: 5;
  }
  
  .modern-bottom-text{
    flex: 5;
    font-size: 18px;
  }  

  .career-card-container p {
    padding-left: 55%;
    font-size: 22px;
  }

  .career-cards-container {
    min-height: auto;
    width: 100%;
    text-align: center;
    flex-direction: column;
    margin: 10px;
  }

  .career-cards {
    background-color: #F5F1E8;
    padding: 30px 10px;
    box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 50px;
    border-radius: 20px;
  }

  .contact-cards-1 {  
    font-size: 20px;
    flex: 2 1;
    color: #000000;
    padding: 0px 50px 0px 35px;
    text-align: left;
  }

  .contact-cards-2 {  
    font-size: 20px;
    padding: 0px 0px 0px 35px;
  }

  .contact-cards-3 {  
    font-size: 20px;
    padding: 0px 5px 0px 0px;
  }

  .contact-cards-1 h5{  
    font-size: 16px;
  }

  .contact-text {
    font-size: 16px;
  }

}

@media (max-width: 430px) {
  .home-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    height: auto;
    position: relative;
  }

  .home-cards-container {
    display: flex;
    flex-direction: column;
  }

  .home-card.left-card {
    flex: 1 1;
    text-align: center;
    padding: 0px;
  }
  
  .home-card.right-card {
    flex: 1 1;
    text-align: center;
    padding: 0px;
  }

  .home-card.right-card h1 {
    font-size: 30px;
  }

  .home-card.right-card p {
    font-size: 25px; 
  }

  .home-button {
    width: 88%; 
    font-size: 17px; 
    margin-top: -15px;
  }

  .home-button img {
    width: 17px;
    height: auto;
    margin-left: 15px;
    justify-content: center;
}

  .bottom-card-container {
    flex-direction: column;
    height: auto;
    padding-left: 0px;
  }
  .bottom-left-img img {
    width: 0%; 
  }

  .bottom-right-text h3 {
    font-size: 15px; 
    margin-top: 10px; 
  }

  .about-section {
    padding: 0px 0px;
    padding-top: 95px;
  }

  .cards-container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    margin: 20px 50px;
    max-width: 1500px;
    gap: 25px;
    flex-direction: column;
  }

  .card {
    background-color: #F5F1E8;
    border: 1px solid #F5F1E8;
    border-radius: 5px;
    padding: 30px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: auto;
  }

  .starter-card-container {
    display: flex;
    flex-direction: column;
  }

  .vertical-line {
    border-left: 0px solid #ffffff;
    height: 0px;
    margin-left: 0px;
    visibility: hidden;
  }

  .starter-right-cards .subtitle p {
    font-size: 20px;
}

  .starter-right-cards .subtitle1 {
    margin-top: 20px;
    line-height: 1.5;
  }

  .modern-card {
    display: flex;
    flex-direction: column;
  }

  .modern-bottom-icon {
    flex: 1 1;
  }

  .career-card-container p {
    padding-left: 10%;
    font-size: 22px;
  }

  .career-card-container {
    padding: 9px 0px 0px 38px;
  }

  .career-card-container h2 {
    color: #000000;
    padding: 0px 40px;
  }

  .career-cards-logo-mcma {
    width: 264px;
    height: 65px;
  }

  .contact-cards-1 {
    font-size: 20px;
    flex: 2 1;
    color: #000000;
    padding: 0px 0px 0px 35px;
    text-align: left;
  }

  .contact-cards-container {
    display: flex;
    flex-direction: column;
  }

  .contact-cards-3 {
    font-size: 20px;
    padding: 0px 0px 0px 35px;
  }

  .contact-cards-2 {
    font-size: 20px;
    padding: 0px 0px 0px 35px;
    margin-bottom: 15px;
  }
}

@media (max-width: 380px) {

  .starter-right-cards .subtitle p{
    margin-top: 18px;
  }

  .starter-right-cards .subtitle1{
    margin-top: 28px;
  }

  .starter-right-cards .subtitle1 p{
    font-size: 18px;
    line-height: 1.4;
  }

  .modern-icon {
    width: 40px;
    height: 40px;
    align-items: center;
  }

  .modern-bottom-text {
    font-size: 16px;
    margin: 0px 0px;
  } 

  .career-card-container {
    padding: 9px 0px 0px 0px; 
  }

  .career-cards-logo-mcma {
    width: 200px;
    height: 50px;
  }

  .career-cards p {
    font-size: 22px;
    line-height: 1.3;
  }

  .career-cards-logo-mlvk {
    width: 80px;
    height: 80px;
  }

  .career-cards-logo-mly {
    width: 100px;
    height: 80px;
  }

  .career-cards-logo-mqa {
    width: 200px;
    height: 100px;
  }
}

@media (max-width: 380px) {
  .starter-left-card .starter-left-text h1 {
    font-size: 30px;
    text-align: center;
  }

  .about-subtitle-icon {
    width: 80px;
    max-width: 100%;
    height: 80px;
    padding-left: 0px;
    text-align: center;
  }

  .starter-right-cards .subtitle {
    display: flex;
    height: 100px;
    flex-direction: column;
    text-align: center;
  }
}